<template>
    <v-container>
        <v-card
        elevation="2">
        <v-card-title class="pb-0 primary--text">Manual Data Update</v-card-title>
        <v-form ref="userForm">
            <v-container>
                <v-row>
                    <v-col>
                        <v-alert
                        outlined
                        border="top"
                        type="info"
                        elevation="2"
                        color="primary"
                        class="mx-1"
                        >
                            To upload data file, make sure the file is in csv format and rename it by standard formating name (Eg: StationName_AnyWord.csv). 
                            Please click the button below to see the csv sample for reference. <br>
                            <strong class="black--text"><span class="red--text">**</span> Example name: "CMSXX_AnyWord.csv"</strong><br>

                            <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                small
                                fab
                                elevation="3"
                                color="green"
                                class="mt-2"
                                v-bind="attrs"
                                v-on="on"
                                @click="downloadSample()"
                                >
                                    <v-icon dense class="white--text">
                                        mdi-microsoft-excel
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>Sample Data Upload</span>
                            </v-tooltip>
                        
                        </v-alert>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col
                    cols="12"
                    md="3"
                    class="pb-0"
                    >
                        <v-select
                        v-on:change="selectValueStation"
                        :items="itemStation"
                        label="Station"
                        dense
                        outlined
                        prepend-icon="mdi-store-24-hour"
                        ></v-select>
                    </v-col>

                    <v-col
                    cols="12"
                    md="3"
                    class="pb-0"
                    >
                        <v-file-input
                        :disabled="modelStation == null"
                        label="File input"
                        show-size
                        truncate-length="15"
                        id="file"
                        ref="file"
                        dense
                        outlined
                        @change="checkDataInput($event)"
                        ></v-file-input>
                    </v-col>
                    <v-col
                    cols="12"
                    md="6"
                    class="pb-0"
                    >
                        <v-btn
                        class="primary"
                        @click="uploadCsv"
                        :disabled="btnUploadDisable"
                        >
                            Upload
                            <v-icon
                            right
                            dark
                            big
                            >
                                mdi-file-upload-outline
                            </v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                </v-container>
            </v-form>
        </v-card>

        <template>
            <div class="text-center" style="margin: 30px 0px;">
                <v-progress-circular
                id="loadingUpload"
                :rotate="360"
                :size="100"
                :width="15"
                :value="value"
                color="rgb(1 157 191)"
                >
                {{ value }}
                </v-progress-circular>
            </div>
        </template>

        <!-- Popups/Dialogs Section - Success Upload -->
        <div class="text-center">
            <v-dialog
            v-model="dialogSuccessUpload"
            width="500"
            >
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                style="display:none;"
                id="showSuccessUpload"
                color="green lighten-1"
                dark
                v-bind="attrs"
                v-on="on"
                small
                >
                    <v-icon small>mdi-note-check-outline</v-icon>
                </v-btn>
            </template>

            <v-card>
                <v-card-title class="text-h5" style="background:#106f79;color:white;">
                Upload Complete <v-icon style="color:white;margin-left:15px;">mdi-note-check-outline</v-icon>
                </v-card-title>

                <v-card-text style="padding:20px 24px;font-weight:bold;">
                Your file were uploaded successfully
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="#106f79"
                    text
                    @click="dialogSuccessUpload = false;"
                >
                    OK
                </v-btn>
                </v-card-actions>
            </v-card>
            </v-dialog>
        </div>

        <!-- Popups/Dialogs Section - Error Upload-->
        <div class="text-center">
            <v-dialog
            v-model="dialogErrorUpload"
            width="500"
            >
            <v-card>
                <v-card-title class="text-h5 red" style="color:white;">
                Error! <v-icon style="color:white;margin-left:15px;">mdi-alert</v-icon>
                </v-card-title>

                <v-card-text style="padding:20px 24px;font-weight:bold;">
                    {{textError}}
                
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    style="color:red !important;"
                    color="primary"
                    text
                    @click="dialogErrorUpload = false; "
                >
                    OK
                </v-btn>
                </v-card-actions>
            </v-card>
            </v-dialog>
        </div>

    </v-container>
</template>

<script>
import axios from 'axios';
import Papa from "papaparse";

export default {
    data: () => ({

        modelStation: null,
        itemStation: [],

        btnUploadDisable: true,

        dialogSuccessUpload: false,
        dialogErrorUpload: false,

        textError: "",

        interval: {},
        value: 0,

        dataInputTemp: null,

        jsonSample: [
            "ST002",
            "6",
            "16.30.00",
            "12",
            "04",
            "2022",
            "0",
            "M17",
            "1",
            "B",
            "26.8024",
            "2",
            "B",
            "5.8100",
            "3",
            "B",
            "32.4667",
            "4",
            "B",
            "2.5300",
            "5",
            "B",
            "29.0778",
            "6",
            "B",
            "0.0200",
            "7",
            "B",
            "0.3000",
            "8",
            "B",
            "35.7729",
            "9",
            "B",
            "3.2687",
            "10",
            "B",
            "23.6896",
            "11",
            "B",
            "1.0991",
            "12",
            "B",
            "4.1038",
            "13",
            "B",
            "1347.5455",
            "14",
            "B",
            "*",
            "15",
            "B",
            "13.3600",
            "16",
            "B",
            "3.5244",
            "17",
            "B",
            "10.9238",
            "#60"
        ]


    }),
    methods: {

        load(){
            // console.log("ok");
            this.getStation();
        },

        getStation(){
            axios.get('https://apis.spatialworks.com.my/sarawak/cms/stations', {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.user.token,
                }
            })
            .then((response) => {
                // console.log(response.data);
                this.itemStation = [];

                for (let i = 0; i < response.data.length; i++) {
                    this.itemStation.push(response.data[i].stationId + " - " + response.data[i].location);  
                }
                
            })
            .catch(error => {
                console.log(error);
            })
        },

        selectValueStation: function(e) {
            // console.log(e);
            this.modelStation = e.split(" - ")[0];
            console.log(this.modelStation);



            // FILE INPUT MANAGEMENT
            if (this.dataInputTemp != null) {

                if (this.dataInputTemp != undefined) {

                    let nameFile = this.dataInputTemp.name.split(".")[0];
                    // console.log(nameFile);

                    // console.log(nameFile.substring(0,3));
                    console.log(nameFile.substring(3,5));

                    console.log(/\p{Lu}/u.test(nameFile.substring(0,3)));

                    if (/\p{Lu}/u.test(nameFile.substring(0,3)) == true) {

                        if (nameFile.substring(0,3) === "CMS") {

                            if (nameFile.substring(3,5) == this.modelStation.substring(3,5)) {
                                if (nameFile.substring(5,6) != "_") {
                                    this.btnUploadDisable = true;
                                    this.textError = "Please make sure the file is in csv format and rename it by standard formating name (Eg: StationName_AnyWord.csv)."
                                    this.dialogErrorUpload = true;
                                }
                                else{
                                    console.log("Success");
                                    this.btnUploadDisable = false;
                                }
                            }
                            else{
                                this.btnUploadDisable = true;
                                this.textError = "Please make sure file upload is station " + this.modelStation;
                                this.dialogErrorUpload = true;
                            }
                            
                        }
                        else{
                            this.btnUploadDisable = true;
                            this.textError = "Please make sure the file is in csv format and rename it by standard formating name (Eg: StationName_AnyWord.csv)."
                            this.dialogErrorUpload = true;
                        }
                        

                    }
                    else{
                        this.btnUploadDisable = true;
                        this.textError = "Please make sure the name of file is in uppercase."
                        this.dialogErrorUpload = true;
                    }
                    

                }
                else{
                    this.btnUploadDisable = true;
                }
                
            }


        },

        downloadSample(){

            // window.open('https://apis.serasi.tech/publicdir/2022/05/13/CMSXX_ANYTEXT.csv', '_blank');
            window.open('https://apis.spatialworks.com.my/publicdir/2022/05/13/CMSXX_ANYTEXT.csv', '_blank');

            // var link = document.createElement("a");
            // link.href = "https://apis.spatialworks.com.my/publicdir/2022/05/13/cf5198530c03_CMSXX_ANYTEXT.csv"
            // link.download = "CMSXX_ANYTEXT.csv";
            // link.click();
            

            // var blob = new Blob([Papa.unparse(this.jsonSample)], { type: 'text/csv;charset=utf-8;' });

            // var link = document.createElement("a");

            // var url = URL.createObjectURL(blob);
            // link.setAttribute("href", url);
            // link.setAttribute("download", 'CMSXX_ANYTEXT.csv');
            // link.style.visibility = 'hidden';
            // document.body.appendChild(link);
            // link.click();
            // document.body.removeChild(link);
        },

        checkDataInput(data){

            this.dataInputTemp = data;


            if (data != undefined) {
                console.log(data);

                let nameFile = data.name.split(".")[0];
                // console.log(nameFile);

                // console.log(nameFile.substring(0,3));
                console.log(nameFile.substring(3,5));

                console.log(/\p{Lu}/u.test(nameFile.substring(0,3)));

                if (/\p{Lu}/u.test(nameFile.substring(0,3)) == true) {

                    if (nameFile.substring(0,3) === "CMS") {

                        if (nameFile.substring(3,5) == this.modelStation.substring(3,5)) {
                            if (nameFile.substring(5,6) != "_") {
                                this.btnUploadDisable = true;
                                this.textError = "Please make sure the file is in csv format and rename it by standard formating name (Eg: StationName_AnyWord.csv)."
                                this.dialogErrorUpload = true;
                            }
                            else{
                                console.log("Success");
                                this.btnUploadDisable = false;
                            }
                        }
                        else{
                            this.btnUploadDisable = true;
                            this.textError = "Please make sure file upload is station " + this.modelStation;
                            this.dialogErrorUpload = true;
                        }
                        
                    }
                    else{
                        this.btnUploadDisable = true;
                        this.textError = "Please make sure the file is in csv format and rename it by standard formating name (Eg: StationName_AnyWord.csv)."
                        this.dialogErrorUpload = true;
                    }
                    

                }
                else{
                    this.btnUploadDisable = true;
                    this.textError = "Please make sure the name of file is in uppercase."
                    this.dialogErrorUpload = true;
                }
                

            }
            else{
                this.btnUploadDisable = true;
            }
            
        },

        uploadCsv(){

            document.getElementById('loadingUpload').style.display = "inline-flex";

            let formData = new FormData();
            formData.append('files', document.getElementById('file').files[0]);

            let succeed = null;

            if (document.getElementById('file').files[0] != null) {
                axios.post( 'https://dev.serasi.tech/sarawak_api/cms/uploadcsv/?token=yuyuhakusho',
                        formData,
                        {
                            // headers: {
                            //     Authorization: 'Bearer ' + this.$store.getters.user.token,
                            // }
                        }
                ).then(function(response){
                    console.log('SUCCESS!!');
                    succeed = "success";
                },
                this.interval = setInterval(() => {
                    
                    if (this.value === 100) {
                        this.dialogSuccessUpload = true;
                        document.getElementById('loadingUpload').style.display = "none";
                        clearInterval(this.interval);
                        // return (this.value = 0)
                        this.value = 0
                    }
                    this.value += 20

                    // clearInterval(this.interval);
                }, 1000)
                )
                .catch(function(){
                    // console.log('FAILURE!!');
                    this.textError = "Something went wrong. Please make sure file uploaded using CSV format and your internet is connected."
                    this.dialogErrorUpload = true;
                });

                console.log(this.value);


                // let myInterval = setInterval(() => {
                //     if (succeed != null) {
                //         this.dialogSuccessUpload = true;
                //     }
                //     else{
                //         console.log("k");
                //     }
                //     clearInterval(myInterval);
                // }, 1000);

                
            }
            else{
                console.log("File Null");
            }
            
        }

    },
    mounted(){
        this.load();
    }
}

</script>

<style lang="scss">

    @import '~scss/main';

    #loadingUpload{
        display: none;
    }

</style>